@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply bg-[#495057] ring hover:ring-offset-2 ring-[#343a40] text-[#f8f9fa] border transition-all duration-200 p-2 rounded-lg ease-linear;
  }

  .edit-window {
    @apply absolute z-50 bg-[#e9ecef] ring ring-offset-4 ring-[#495057] p-2 rounded-xl mt-4;
  }

  .info-window {
    @apply bg-[#e9ecef] ring ring-offset-4 ring-[#495057] p-2 rounded-xl mt-8;
  }

  .input {
    @apply rounded-lg p-1 w-auto outline-none border-none focus:outline-[#212529];
  }

  .select {
    @apply bg-[#495057] text-sm text-[#f8f9fa] rounded-lg ring ring-[#343a40] hover:ring-offset-2 w-full p-2.5 transition-all duration-200 ease-linear;
  }

  .select-info {
    @apply bg-[#495057] text-sm text-[#f8f9fa] rounded-lg ring ring-[#343a40] hover:ring-offset-2 w-full p-1 transition-all duration-200 ease-linear;
  }

  .input-label {
    @apply bg-transparent w-28;
  }

  .description {
    @apply flex items-start justify-center bg-[#adb5bd] rounded-lg p-2 ring ring-[#343a40] ring-offset-2;
  }

  .width-margin {
    @apply w-auto my-2;
  }
}

.download {
  width: 125px;
  height: 45px;
  border-radius: 20px;
  border: none;
  box-shadow: 1px 1px rgba(107, 221, 215, 0.37);
  padding: 5px 10px;
  background: rgb(47, 93, 197);
  margin-left: 50%;
  margin-bottom: 2rem;
  background: linear-gradient(
    160deg,
    rgba(47, 93, 197, 1) 0%,
    rgba(46, 86, 194, 1) 5%,
    rgba(47, 93, 197, 1) 11%,
    rgba(59, 190, 230, 1) 57%,
    rgba(0, 212, 255, 1) 71%
  );
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 505;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  filter: drop-shadow(0 0 10px rgba(59, 190, 230, 0.568));
  transition: 0.5s linear;
}

.download .mysvg {
  display: none;
}

.download:hover {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.5s linear;
}

.download:hover .texto {
  display: none;
}

.download:hover .mysvg {
  display: inline;
}

.download:hover::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3.5px solid transparent;
  border-top: 3.5px solid #fff;
  border-right: 3.5px solid #fff;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-delete {
  outline: none;
  border: none;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

#delete {
  color: red;
  display: none;
  cursor: pointer;
  margin-right: 1rem;
  transition: all 0.2s ease-in-out;
}

#edit {
  color: green;
  display: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#delete:hover,
#edit:hover {
  transform: scale(2);
}

.desrow:hover #delete,
.desrow:hover #edit {
  display: block;
}
